module.exports = {
  api: {
    host: 'https://wims2.winglegroup.com',
    suffix: '.jsonld',
    auth: '/login_check',
    refresh: '/api/token/refresh',
  },
  recaptcha: {
    siteKey: '6LdT4-wZAAAAAJNJeqOXBzjzJIFOx2eYDr4d4RQF',
  },
};
