<template>
  <v-container>
    <p>There is a Homepage.</p>
    <p>Some kind of dashboard will be here.</p>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped></style>
