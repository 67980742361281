<template>
  <v-card width="100%" max-width="420px" elevation="4" :loading="loading">
    <v-card-title>Log In</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validation.status">
        <v-text-field
          v-model="form.username"
          type="email"
          label="Username"
          required
          :rules="validation.requiredRules"
        />
        <v-text-field
          v-model="form.password"
          type="password"
          label="Password"
          required
          :rules="validation.requiredRules"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" :disabled="loading" @click="submit">Login</v-btn>
    </v-card-actions>
    <v-card-text v-if="errorMessage" class="body-1 error white--text pt-4">
      {{ errorMessage }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      form: { username: null, password: null },
      validation: {
        status: false,
        requiredRules: [(v) => !!v || 'Required'],
      },
      errorMessage: null,
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.authorize();
      }
    },
    authorize() {
      this.loading = true;
      this.$client
        .auth(this.form)
        .then(this.logIn)
        .catch((err) => {
          let message;
          if (err.response?.data) {
            const { message: errMessage } = err.response.data;
            message = `Error: ${errMessage}`;
          }
          this.errorMessage = message || err.message || 'Something went wrong';
          this.failed = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
