<template>
  <v-container class="max-width">
    <v-pagination v-model="innerPage" :length="pages" v-bind="pagerAttrs" />
  </v-container>
</template>

<script>
export default {
  name: 'Pager',
  model: {
    event: 'change',
    prop: 'page',
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    length: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 30,
    },
    pagerProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    pagerAttrs() {
      return {
        totalVisible: 13,
        ...this.pagerProps,
      };
    },
    innerPage: {
      get() {
        return this.page;
      },
      set(page) {
        this.$emit('change', page);
      },
    },
    pages() {
      return this.length || Math.ceil(this.total / this.itemsPerPage);
    },
  },
};
</script>
