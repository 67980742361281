import axios from 'axios';
import Collection from 'Lib/Collection';
import { HttpError } from 'Lib/Throwable';
import { load as Recaptcha } from 'recaptcha-v3';
import { recaptcha as reConf } from '../../project.config';

class Api {
  #config = {};
  #re;
  #store;

  constructor(config, store) {
    this.#config = config;
    this.#store = store;
  }

  #loadRecaptcha = async (key) => {
    return Recaptcha(key).then((recaptcha) => {
      this.#re = recaptcha;
      return recaptcha;
    });
  };
  #getReToken = async (action) => {
    if (!this.#re) {
      await this.#loadRecaptcha(reConf.siteKey);
    }
    return this.#re.execute(action);
  };
  get = async (url, options) => {
    return this.request(url, 'get', options);
  };
  post = async (url, options = {}) => this.request(url, 'post', options);

  async patch(url, options = {}) {
    return this.request(url, 'patch', options);
  }

  request = async (endpoint, method = 'get', options = {}) => {
    const { re, ld, pub, ...rest } = options;
    const url = '' + this.buildUrl(endpoint);
    if (rest.headers === void 0) {
      rest.headers = {};
    }
    if (ld) {
      rest.headers['Accept'] = 'application/ld+json';
    }
    if (re === true) {
      const path = new URL(url).pathname.replace(/^\/api\/|\.json(ld)?/gi, '').replace(/\/\d+/g, '/id');
      rest.headers['recaptcha'] = await this.#getReToken(`${method}_${path}`);
    }
    if (!pub && this.authToken) {
      rest.headers['Authorization'] = `Bearer ${this.authToken}`;
    }
    return axios({ url, method, ...(rest || {}) }).then((res) => {
      if (res.status >= 400) {
        throw new HttpError(res.statusText, res.status, res);
      }
      return res;
    });
  };
  /**
   * @param {*} url
   * @return URL
   */
  buildUrl = (url) => {
    let _url;
    try {
      _url = new URL(url);
    } catch (e) {
      _url = new URL(this.#config.host);
      const [pathname, search, hash] = url.split(/[#?]/);
      pathname && (_url.pathname = pathname);
      search && (_url.search = search);
      hash && (_url.hash = hash);
    }
    return _url;
  };
  auth = async (cred) => {
    const { username, password } = cred || {};
    if (!username || !password) {
      throw new Error('Empty credentials');
    }
    return this.post(this.#config.auth, {
      data: { username, password },
      re: true,
    }).then(({ data }) => {
      return data || { token: false, refresh_token: false };
    });
  };
  refreshAuthToken = async (refresh_token) => {
    return this.post(this.#config.refresh, {
      data: { refresh_token },
      re: true,
      pub: true,
    })
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return null;
      });
  };
  register = async (data) =>
    this.post(this.#config.register, { data, re: true }).then((res) => {
      if (res.status === 201) {
        return true;
      }
      throw new HttpError(res.statusText, res.status, res);
    });
  getItemsCollection = async (endpoint, { model = null, options = {} } = {}) => {
    const { status, statusText, data } = await this.get(endpoint, options || {});
    if (status >= 400) {
      throw new Error(`${status}: ${statusText}`);
    }
    return this.parseLinkedData(data, model, { endpoint, options });
  };
  getItemsList = async (endpoint, { model = null, options = {} } = {}) => {
    const { status, statusText, data } = await this.get(endpoint, options || {});
    if (status >= 400) {
      throw new Error(`${status}: ${statusText}`);
    }
    return this.processData(data, model);
  };
  getItem = async (endpoint, { model = null, options = {} } = {}) => {
    const { status, statusText, data } = await this.get(this.buildUrl(endpoint), options || {});
    if (status >= 400) {
      throw new Error(`${status}: ${statusText}`);
    }
    return this.processData(data, model);
  };
  processData = (data, model = null) => {
    if (!model) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map((item) => new model(item));
    }
    if (typeof data === 'object') {
      return new model(data);
    }
    return data;
  };
  parseLinkedData = (data, model, params) => new Collection(data, { model, params, api: this });

  get authToken() {
    return this.#store.state.token;
  }
}

export default Api;
