import moment from 'moment';

export function isJWTExpired(token) {
  const [header, payload] = (token || '').split('.');
  if (header !== void 0 && payload !== void 0) {
    const { exp } = JSON.parse(atob(payload)) || {};
    return exp && exp <= ~~(+new Date() / 1000);
  }
  return true;
}

const irregular = {
  child: 'children',
  goose: 'geese',
  man: 'men',
  woman: 'women',
  tooth: 'teeth',
  foot: 'feet',
  mouse: 'mice',
  person: 'people',
};

export function pluralize(string, amount = 0) {
  if (typeof string !== 'string') {
    return;
  }
  const rule = new Intl.PluralRules('en-US').select(amount);
  if (rule === 'one') {
    return string;
  }
  if (string.match(new RegExp(`^(${Object.keys(irregular).join('|')})$`, 'i'))) {
    return irregular[string.toLowerCase()];
  }
  const exceptions = [
    'roof',
    'belief',
    'chef',
    'chief',
    'photo',
    'piano',
    'halo',
    'sheep',
    'series',
    'species',
    'deer',
  ].join('|');
  const exRex = new RegExp(`^(${exceptions})$`, 'i');
  if (exRex.test(string)) {
    return string + 's';
  }
  if (string.match(/is$/i)) {
    return string.replace(/is$/i, 'es');
  }
  if (string.match(/s[sh]?|ch|x|z$/i)) {
    return string + 'es';
  }
  if (string.match(/fe?$/i)) {
    return string.replace(/fe?$/i, 'ves');
  }
  if (string.match(/[^aeiouy]y$/i)) {
    return string.replace(/y$/i, 'ies');
  }
  if (string.match(/o$/i)) {
    return string + 'es';
  }
  if (string.match(/on$/i)) {
    return string.replace(/on$/i, 'a');
  }
  return string + 's';
}

export function deepMerge(a, ...rest) {
  if (typeof a !== 'object' || a === null || Array.isArray(a)) {
    throw new Error('Invalid type');
  }
  const result = { ...a };
  for (const item of rest) {
    if (Array.isArray(item)) {
      continue;
    }
    for (const key of Object.keys(item)) {
      const valA = result[key];
      const valB = item[key];
      if (valA === void 0 || valA === null) {
        result[key] = valB;
      } else if (Array.isArray(valA)) {
        if (Array.isArray(valB)) {
          result[key] = [...valA, ...valB];
        }
      } else if (typeof valA === 'object') {
        if (typeof valB === 'object') {
          result[key] = deepMerge(valA, valB);
        }
      } else {
        result[key] = valB;
      }
    }
  }
  return result;
}

export function createElement(tagName, attributes = {}, container = null) {
  const el = document.createElement(tagName);
  Object.entries(attributes || {}).forEach(([attr, value]) => {
    el.setAttribute(attr, value);
  });
  if (container) {
    let target;
    if (container instanceof Element) {
      target = container;
    } else if (typeof container === 'string') {
      target = document.querySelector(container);
    } else if (typeof container === 'function') {
      target = container();
    }
    if (target instanceof Element) {
      target.appendChild(el);
    }
  }
  return el;
}

export function format(template, params = {}) {
  return template.replace(/{[A-Za-z_-]+}/gm, (match) => {
    const param = match.replace(/[{}]/g, '');
    return params[param] || match;
  });
}

export function dataURItoBlob(dataURI) {
  const [header, data] = dataURI.split(';');
  const mime = header.split(':')[1];
  const [encoding, payload] = data.split(',');
  if (encoding !== 'base64') {
    throw new Error('There is not base64 MIME-type');
  }
  const byteString = atob(payload);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mime });
}

export function persist(key, value, encode = false) {
  if (encode) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
  return value;
}

export function restore(key, def = void 0, decode = false) {
  try {
    const value = localStorage.getItem(key);
    if (decode) {
      return JSON.parse(value) || def;
    }
    return value;
  } catch (e) {
    return def;
  }
}

export function formatDateTime(value, format = 'YYYY-MM-DD HH:mm:ss') {
  if (value) {
    return moment(value).format(format);
  }
  return value || '';
}
