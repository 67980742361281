<template>
  <div>
    <search-bar :config="model.searchConf" persist :module="module" @submit="applyFilters" />
    <v-data-table
      hide-default-footer
      :items="items"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :loading="loading"
      dense
      @click:row="navToItem"
    >
      <template #footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-spacer></v-spacer>
          <span class="grey--text">Items per page</span>
          <v-menu offset-y :disabled="!total">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                {{ itemsPerPage }}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(number, index) in perPageOpts" :key="index" @click="setPerPage(number)">
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="mr-3">
            <span>{{ itemStart }} - {{ itemStop }} of {{ total }}</span>
          </div>
        </v-row>
      </template>
    </v-data-table>
    <pager :page="page" :items-per-page="itemsPerPage" :total="total" @change="setPage" />
  </div>
</template>

<script>
import Pager from 'Components/Pager';
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';
import SearchBar from 'Components/Search/SearchBar';
export default {
  name: 'ListingTable',
  components: { SearchBar, Pager },
  props: {
    module: {
      type: String,
      required: true,
    },
    perPageOpts: {
      type: Array,
      default: () => [30, 100, 200],
    },
  },
  data() {
    return {
      page: 1,
      collection: null,
      itemsPerPage: 30,
      loading: false,
      filters: {},
    };
  },
  computed: {
    total() {
      return this.collection?.total || 0;
    },
    itemStart() {
      return (this.page - 1) * this.itemsPerPage + +(this.total > 0);
    },
    itemStop() {
      return Math.min(this.itemsPerPage * this.page, this.total);
    },
    items() {
      return this.collection?.items;
    },
    moduleConf() {
      return MODULES_MAP[this.module];
    },
    model() {
      return this.moduleConf?.model;
    },
    headers() {
      if (this.model) {
        return this.model.getHeaders();
      }
      return [
        {
          text: 'ID',
          value: 'id',
          width: '1%',
        },
        {
          text: 'Name',
          value: 'name',
        },
      ];
    },
  },
  created() {
    // this.getData();
  },
  methods: {
    async getData() {
      if (!this.module) {
        return;
      }
      this.loading = true;
      this.collection = null;
      this.collection = await this.$api.getApiCollection(this.module, this.buildFilters());
      this.loading = false;
    },
    buildFilters() {
      const { itemsPerPage, page } = this;
      const defaultFilters = this.model?.getDefaultFilters() || {};
      return { ...defaultFilters, ...this.filters, itemsPerPage, page };
    },
    setPerPage(number) {
      this.itemsPerPage = number;
      this.$emit('update:items-per-page', number);
      this.page = 1;
      this.getData();
    },
    setPage(number) {
      this.page = number;
      this.$emit('update:page', number);
      this.getData();
    },
    navToItem(item) {
      const { id, moduleName: module } = item;
      this.$router.push({ name: 'ModuleItem', params: { module, id } });
    },
    applyFilters(filters) {
      this.filters = filters;
      this.getData();
    },
  },
};
</script>
