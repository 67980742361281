<template>
  <div class="search-bar">
    <v-text-field
      v-model="search"
      type="text"
      dense
      outlined
      clearable
      hide-details
      color="blue"
      :placeholder="placeholder"
    />
    <v-btn right outlined color="success" height="40" @click="runSearch">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn right outlined height="40" :color="hasFilters ? 'info' : 'gray'" @click="drawer = !drawer">
      <v-icon>{{ hasFilters ? 'mdi-filter-check' : 'mdi-filter' }}</v-icon>
    </v-btn>
    <v-navigation-drawer v-model="drawer" app temporary bottom light right width="400">
      <filters :filters-list="config.filtersList" @change="updateFilters" @submit="runSearch" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import Filters from 'Components/Search/Filters';
import SearchModel from 'Models/SearchModel';
import FilterTypes from 'Components/Search/FilterTypes';

function isEmpty(val) {
  if (typeof val === 'undefined' || val === null) {
    return true;
  }
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  if (typeof val === 'object') {
    return Object.values(val).filter((val) => !isEmpty(val)).length === 0;
  }
  return false;
}

export default {
  name: 'SearchBar',
  components: { Filters },
  props: {
    config: {
      type: SearchModel,
      required: true,
    },
    persist: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      drawer: false,
      filters: {},
      search: '',
    };
  },
  computed: {
    placeholder() {
      const { searchbarProp } = this.config;
      const conf = this.config.filtersList.find((el) => el.id === searchbarProp);
      if (conf) {
        return conf.title || conf.label;
      }
      return 'Search...';
    },
    hasFilters() {
      return !isEmpty(this.filters);
    },
  },
  created() {
    if (this.persist) {
      this.filters = this.restoreFilters(this.module);
      this.runSearch();
    }
  },
  methods: {
    updateFilters(filters) {
      this.filters = { ...filters };
      this.persistFilters(this.filters);
    },
    runSearch() {
      const { config, filters, search } = this;
      const { searchbarProp, filtersList } = config;
      const { [searchbarProp]: inFilters } = filters;
      this.filters = { ...this.filters, [searchbarProp]: search || inFilters };
      this.$emit('submit', this.convertFilters(filters, filtersList));
      this.drawer = false;
    },
    convertFilters(rawFilters, filtersList) {
      const filters = {};
      const isEmpty = (value) => {
        if (typeof value === 'undefined' || value === null) {
          return true;
        }
        if (Array.isArray(value)) {
          return value.length === 0;
        }
        if (typeof value === 'object') {
          return isEmpty(value?.from) && isEmpty(value?.to);
        }
        return false;
      };
      const extractRangeValues = (value, rangeConf, strict = { from: false, to: false }) => {
        const getProp = (id) => {
          const key = strict[id] ? 'strictId' : 'id';
          return rangeConf[id][key];
        };
        const entries = [];
        for (const id of ['from', 'to']) {
          if (!isEmpty(value[id])) {
            entries.push([getProp(id), value[id]]);
          }
        }
        return entries;
      };
      for (const conf of filtersList) {
        const { id, filter, rangeConf } = conf;
        const value = rawFilters[id];
        if (isEmpty(value)) {
          continue;
        }
        if (filter === FilterTypes.FILTER_RANGE) {
          extractRangeValues(value, rangeConf).forEach(([k, v]) => {
            filters[k] = v;
          });
        } else {
          filters[id] = value;
        }
      }
      return filters;
    },
    persistFilters(filters) {
      if (this.persist && this.module) {
        this.$store.commit('setFilters', { module: this.module, filters });
      }
    },
    restoreFilters(module) {
      if (module) {
        return this.$store.state.filters[module] || {};
      }
      return {};
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  background: white;
}
</style>
