import BaseModel from 'Models/BaseModel';
import { formatDateTime } from 'Lib/Utils';
import { MODULE_NEWSLETTER_EMAILS } from 'Lib/enums/ApiModules';
import SearchModel from 'Models/SearchModel';
import FilterTypes from 'Components/Search/FilterTypes';
import FieldTypes from 'Components/Search/FieldTypes';

/**
 * @typedef {Object} Body
 * @property {string} text
 * @property {Object} contentType
 * @property {string} contentType.name
 */

/**
 * @typedef {Object} Attachment
 * @property {number} id
 * @property {string} originalName
 * @property {string} downloadUrl
 * @property {string} uid
 */

/**
 * @class NewsletterEmail
 * @property {number} id
 * @property {number} uniqueId
 * @property {string} subject
 * @property {string} domain
 * @property {string} dateReceived
 * @property {Body[]} [bodies]
 * @property {Attachment[]} [files]
 */
export default class NewsletterEmail extends BaseModel {
  static MODULE = MODULE_NEWSLETTER_EMAILS;

  /**
   * @type {SearchModel}
   */
  static searchConf = new SearchModel([
    {
      id: 'id',
      title: 'ID',
      filter: FilterTypes.FILTER_SINGLE,
      type: FieldTypes.FIELD_TEXT,
    },
    {
      id: 'subject',
      title: 'Subject',
      filter: FilterTypes.FILTER_SINGLE,
      type: FieldTypes.FIELD_TEXT,
    },
    {
      id: 'domain',
      title: 'From',
      filter: FilterTypes.FILTER_SINGLE,
      type: FieldTypes.FIELD_TEXT,
    },
    {
      id: 'dateReceived',
      label: 'Received',
      title: 'Received',
      filter: FilterTypes.FILTER_RANGE,
      type: FieldTypes.FIELD_DATE,
      rangeConf: {
        from: {
          id: 'dateReceived[after]',
          strictId: 'dateReceived[strictly_after]',
        },
        to: {
          id: 'dateReceived[before]',
          strictId: 'dateReceived[strictly_before]',
        },
      },
    },
  ]);

  static getHeaders = () => {
    return [
      {
        text: 'ID',
        value: 'id',
        width: '1%',
      },
      {
        text: 'Subject',
        value: 'subject',
      },
      {
        text: 'From',
        value: 'domain',
      },
      {
        text: 'Date',
        value: 'receivedAt',
        cellClass: 'no-wrap',
      },
    ];
  };

  static getDefaultFilters = () => {
    return {
      'order[dateReceived]': 'desc',
    };
  };

  get receivedAt() {
    return formatDateTime(this.props.dateReceived, 'YYYY-MM-DD');
  }
}
