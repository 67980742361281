<template>
  <h1 class="headline">{{ title }}</h1>
</template>

<script>
import { MODULE_NEWSLETTER_EMAILS } from 'Lib/enums/ApiModules';

const modulesData = {
  [MODULE_NEWSLETTER_EMAILS]: { title: 'Newsletter Emails' },
};

export default {
  name: 'MainTitle',
  computed: {
    title() {
      const { path, params } = this.$route;
      if (/^\/modules\//i.test(path)) {
        return this.getModuleTitle(params.module);
      }
      if (!path || path === '/') {
        return 'Homepage';
      }
      return '';
    },
  },
  methods: {
    getModuleTitle(module) {
      return modulesData[module]?.title || '';
    },
  },
};
</script>
