<template>
  <v-container>
    <newsletter-email v-if="module === Modules.MODULE_NEWSLETTER_EMAILS" :item="item" />
  </v-container>
</template>

<script>
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';
import NewsletterEmail from 'Components/ItemsViews/NewsletterEmail';

export default {
  name: 'ModuleItem',
  components: { NewsletterEmail },
  data() {
    return {
      item: null,
      loading: false,
    };
  },
  computed: {
    module() {
      return this.$route.params.module;
    },
    id() {
      return this.$route.params.id;
    },
    moduleConf() {
      return MODULES_MAP[this.module];
    },
    title() {
      return this.moduleConf?.title || `${this.module}: ${this.id}`;
    },
  },
  watch: {
    $route() {
      this.getItem();
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    async getItem() {
      this.item = null;
      this.loading = true;
      this.item = await this.$api.getApiItem(this.module, this.id).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>
