import Vue from 'vue';
import Axios from 'axios';
import Api from 'Lib/Api';
import ApiMapper from 'Lib/ApiMapper';
import config from '../project.config';
import CommonMixin from 'Mixins/CommonMixin';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';

import 'Assets/styles/main.scss';
import 'Lib/filters';

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

const apiClient = new Api(config.api, store);
Vue.prototype.$client = apiClient;
Vue.prototype.$api = new ApiMapper(apiClient);

Vue.mixin(CommonMixin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
