<template>
  <v-list nav dense>
    <v-list-item-group>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-divider class="mb-1" />
      <v-list-item
        v-for="(link, i) in modulesLinks"
        :to="{ name: 'ModuleListing', params: { module: link.module } }"
        :key="i"
        active-class=""
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ link.title }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'SidebarMenu',
  computed: {
    modulesLinks() {
      return [{ module: this.Modules.MODULE_NEWSLETTER_EMAILS, title: 'Newsletter Emails', icon: 'mdi-email' }];
    },
  },
};
</script>
