<template>
  <div class="filters-panel">
    <div class="filters-header">
      <span>Filters</span>
      <div>
        <v-btn right small plain @click="collapseAll">
          <v-icon>mdi-arrow-collapse-vertical</v-icon>
        </v-btn>
        <v-btn right small plain @click="expandAll">
          <v-icon>mdi-arrow-expand-vertical</v-icon>
        </v-btn>
      </div>
    </div>
    <v-form class="filters-wrapper scrollable custom-scrollbar" @submit="apply">
      <v-expansion-panels multiple v-model="panels">
        <v-expansion-panel v-for="(item, i) in filtersList" :key="i">
          <v-expansion-panel-header style="padding: 0 5px">
            <template #default="{ open }">
              <div style="display: flex; flex-direction: column; row-gap: 10px">
                <div style="font-weight: bolder">{{ item.title }}</div>
                <div v-if="!open && formatFilterValue(item)" v-html="formatFilterValue(item)" />
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 5px">
            <filter-entry :value="filters[item.id]" :config="item" @change="setFilterValue(item, $event)" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <div class="filters-footer">
      <v-btn color="gray" outlined @click="reset">Reset filters</v-btn>
      <v-btn color="success" @click="apply">Apply filters</v-btn>
    </div>
  </div>
</template>

<script>
import FilterTypes from 'Components/Search/FilterTypes';
import FieldTypes from 'Components/Search/FieldTypes';
import FilterEntry from 'Components/Search/FilterEntry';

export default {
  name: 'Filters',
  components: { FilterEntry },
  props: {
    filtersList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panels: [],
      filters: {},
    };
  },
  methods: {
    collapseAll() {
      this.panels = [];
    },
    expandAll() {
      this.panels = this.filtersList.map((el, i) => i);
    },
    formatFilterValue(conf) {
      const { id, filter } = conf;
      const { [id]: value } = this.filters;
      if (value === null || typeof value === 'undefined') {
        return '';
      }
      const formatter = {
        [FilterTypes.FILTER_SINGLE]: this.formatSingleValue,
        [FilterTypes.FILTER_RANGE]: this.formatRangeValue,
        [FilterTypes.FILTER_LIST]: this.formatListValue,
      }[filter];
      return typeof formatter === 'function' ? formatter(conf, value) : value;
    },
    formatSingleValue(conf, value) {
      if ([FieldTypes.FIELD_AUTOCOMPLETE, FieldTypes.FIELD_SELECT].includes(conf.type)) {
        return 'to do';
      }
      return value;
    },
    formatRangeValue(conf, value) {
      return `from <b>${value?.from || '...'}</b> to <b>${value?.to || '...'}</b>`;
    },
    formatListValue(conf, value) {
      return value;
    },
    setFilterValue(conf, value) {
      this.filters = {
        ...this.filters,
        [conf.id]: value,
      };
      this.$emit('change', this.filters);
    },
    apply() {
      this.$emit('change', this.filters);
      this.$emit('submit', this.filters);
    },
    reset() {
      this.filters = {};
      this.apply();
    },
  },
};
</script>

<style scoped lang="scss">
$shadow: 0 0 12px -3px #636363;

.filters-panel {
  max-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.filters-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background: white;
  box-shadow: $shadow;
}

.filters-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.filters-footer {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding: 10px;
  box-shadow: $shadow;
  flex-wrap: nowrap;
}
</style>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
