import Api from 'Lib/Api';
import NewsletterEmail from 'Models/NewsletterEmail';
import { MODULE_NEWSLETTER_EMAILS } from 'Lib/enums/ApiModules';

export default class ApiMapper {
  /**
   * @type {Api}
   */
  #api;

  constructor(api) {
    if (!(api instanceof Api)) {
      throw new Error('"api" must be instance of Api');
    }
    this.#api = api;
    this.modelsMap = {
      [MODULE_NEWSLETTER_EMAILS]: NewsletterEmail,
    };
  }

  async getUserProfile() {
    return this.#api.getItem('/api/users/profile', { options: { re: true } });
  }

  async updateProfile(data = {}) {
    const res = await this.#api.patch('/api/users/profile', { data });
    if (res.status === 400) {
      throw new Error('Invalid input');
    }
    if (res.status === 404) {
      throw new Error('Resource not found');
    }
    return res.data;
  }

  getApiCollection(module, params) {
    const model = this.modelsMap[module] || null;
    const config = { model, options: { params, re: true, ld: true } };
    return this.#api.getItemsCollection(`/api/${module}`, config);
  }

  getApiItem(module, id) {
    const model = this.modelsMap[module] || null;
    const config = { model, options: { re: true, ld: false } };
    return this.#api.getItem(`/api/${module}/${id}`, config);
  }
}
